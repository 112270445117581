<template>
    <div class="container contact">
        <div class="contact__img-wrap">
            <picture>
                <source srcset="/img/contact/contact-picture.webp" type="image/webp">
                <source srcset="/img/contact/contact-picture.jpg" type="image/jpeg">
                <img class="contact__img" src="/img/contacts/contact-picture.jpg" width="620" height="625" alt="Contact form">
            </picture>
        </div>
        <div class="contact__block2">
            <div class="contact__title">
                <h2 class="contact__title-h2">{{ $t('general.we-re-here-to-help') }}</h2>
                <p class="contact__title-p">
                    {{ $t('general.our-team-will-answer') }}
                </p>
                <p :class="success ? 'contact__title-message-success' : 'contact__title-message-fail'" v-if="message">
                    {{ message }}</p>
                <div class="contact__title-form-section">
                    <form @submit.prevent="submitForm">
                        <input type="text" :placeholder="$t('general.name')" v-model="formData.name" required>
                        <input type="email" :placeholder="$t('general.email')" v-model="formData.email" required>
                        <textarea :placeholder="$t('general.message')"
                                  v-model="formData.message"
                                  required
                                  rows="8"></textarea>
                        <v-btn
                            depressed
                            max-width="221px"
                            type="submit"
                            color="#1E5CBA"
                            class="contact__btn-form"
                            :disabled="loader"
                            :loading="loader"
                        >
                            {{ $t('general.send-message') }}
                        </v-btn>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            formData: {
                name: '',
                email: '',
                message: ''
            },
            csrfToken: '',
            message: '',
            success: false,
            loader: false,
        };
    },
    created() {
        //Fetch token after load
        this.fetchCsrfToken();
    },
    methods: {
        //Get CSRF-token from backend
        fetchCsrfToken() {
            axios.get('/csrf-token')
                .then(response => {
                    this.csrfToken = response.data.csrf_token;
                })
                .catch(error => {
                    this.success = false
                    console.error('Error:', error);
                    alert('Failed to get CSRF token');
                });
        },
        // Send post with CSRF-token
        submitForm() {
            this.loader = true;
            axios.post('/contact-form', this.formData, {
                headers: {
                    'X-CSRF-TOKEN': this.csrfToken
                }
            })
                .then(response => {
                    if (response.data.success) {
                        this.success = true
                        this.message = 'E-mail was sent successfully';
                        // Reset form after success
                        this.formData.name = '';
                        this.formData.email = '';
                        this.formData.message = '';
                    } else {
                        this.success = false
                        this.message = 'An error occurred while submitting the form';
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    this.message = 'An error occurred while submitting the form';
                }).finally(() => {
                this.loader = false;
            })
        }
    }
};
</script>

<style scoped lang="scss">
@import "../../../scss/mixins";

.container {
    padding: 0;
    background: #fff;

    @include lg-block() {
        max-width: 97vw;
    }
    @include sm-block() {
        max-width: 96vw;
    }
    @media screen and(width < 600px) {
        max-width: 95vw;
    }
    @media screen and(width < 450px) {
        max-width: 92.5vw;
    }
}

.contact {
    margin-top: 40px;
    display: flex;
    border-radius: 10px;

    @media (max-width: 767px) {
        padding: 16px;
    }

    @include lg-block() {
        flex-wrap: wrap;
        margin: 16px auto;
    }

    &__img-wrap {
        min-width: 50%;
        min-height: 625px;
        @media (max-width: 767px) {
            min-width: 100%;
            height: auto;
            min-height: auto;
        }
        @media (min-width: 768px) {
            flex: 1;
        }
    }

    &__img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 10px 0 0 10px;
        @media (max-width: 767px) {
            border-radius: 10px;
        }
    }

    .contact__block2 {
        padding: 40px;
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;

        @include lg-block() {
            padding: 20px 30px;
        }

        @media (max-width: 767px) {
            padding: 0;
        }

        .contact__title {
            display: flex;
            justify-content: center;
            flex-direction: column;
            max-width: 95%;
            gap: 11px;

            &-message-success {
                color: #46de46
            }

            &-message-fail {
                color: red
            }

            @include sm-block() {
                max-width: 100%;
            }

            &-h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 44px;
                color: #000;

                @include lg-block() {
                    font-size: 22px;
                }
            }

            &-p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #000;

                @include lg-block() {
                    font-size: 16px;
                }
            }

            &-form-section {
                width: 100%;
                margin: 0 auto; /* Центрируем форму по горизонтали */
                box-sizing: border-box; /* Учитываем padding в общей ширине */
                border-radius: 20px; /* Скругление углов формы */

                form {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;

                    input, textarea {
                        padding: 10px; /* Поля внутри полей ввода */
                        margin-bottom: 10px; /* Отступ между элементами */
                        box-sizing: border-box; /* Учитываем padding в общей ширине */
                        border: 1px solid #ccc; /* Граница полей */
                        border-radius: 5px; /* Скругление углов полей */
                        font-size: 16px; /* Размер шрифта */
                        resize: none;
                    }
                }
            }
        }
    }

    &__btn-form {
        color: #fff;
        min-height: 48px;
        text-transform: none;
        letter-spacing: normal;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;

        &:hover {
            background: #154286 !important;
        }
    }
}

</style>


