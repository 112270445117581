<template>
    <div class="landing">
        <Header />
        <ContactsMainSection />
        <Footer />
    </div>
</template>

<script>

import Header from "@/views/static/sections/Header.vue"
import ContactsMainSection from "@/views/static/contacts-page-sections/ContactsMainSection.vue";
import Footer from "@/views/static/sections/Footer.vue"

export default {
    name: "Contacts",
    components: {Header, ContactsMainSection, Footer}
}
</script>

<style scoped>

.landing {
    background: #F3F1F1;
}

</style>
